#login-page {
    background-color: #002140;
    height: 100vh;
}

.login-header {
    color: white;
}

#login-box {
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#login-box h1 {
    padding-top: 3vh;
}

#login-box .login-form {
    width: 300px;
    padding-top: 30px;
}

.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    width: 100%;
}

#env-footer {
    color: rgb(189, 189, 189);
    font-size: 8pt;
}

#login-box .logo {
    padding-top: 10vh;
    width: 150px;
    /* height: auto; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* background: rgba(255, 255, 255, 0.2); */
    /* margin: 16px; */
}