.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 6px;
    font-size: 18px;
    color: #b80000;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: rgb(255, 56, 56);
}

.ant-row .ant-form-item {
    margin-bottom: 4px;
}