.ant-carousel {
    max-height: 350px;
    width: 100%;
    height: auto;
    background-color: rgb(199, 199, 199);
}

.ant-carousel .slick-slide {
    max-height: 350px;
    overflow: hidden;
    width: auto;
}

.ant-carousel .slick-slide img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 350px;
}

.ant-carousel .slick-dots li {
    background-color: rgb(199, 199, 199);
    height: 4px !important;
}

.ant-carousel .slick-dots button {
    height: 4px !important;
}