.logo {
    max-width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* background: rgba(255, 255, 255, 0.2); */
    /* margin: 16px; */
}

.site-layout .site-layout-background {
    background: #fff;
}